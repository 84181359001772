import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled';

import Layout from '../components/Layout'
import { css } from '@emotion/core';

// export default () => {

//     const [height, setHeight] = useState(0)
//     const ref = useRef(null)

//     useEffect(() => {
//         setHeight(ref.current.clientHeight)
//     },[ref.current])

//     return (
//         <Layout>
//             <div className="services">
//                 <div style={{ alignItems: 'center', marginLeft: 0 }} className="about__pre">
//                     <hr style={{ marginTop: '25rem' }} />
//                 </div>
//                 <div style={{ position: 'relative' }} className="services__container">
//                     <div className="no-small" style={{ width: '2px', height: (height/2)+41, backgroundColor: 'var(--color-primary)', position: 'absolute', marginTop: '8.5rem', left: 0 }} />
//                     <div ref={ref} className="content">
//                         <div className="about__title" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
//                             <div style={{ width: '4rem', height: '2px', backgroundColor: 'var(--color-primary)', marginLeft: '-2.5rem', marginRight: '1rem' }} className="line-hor" />
//                             <h1>Services</h1>
//                         </div>
//                         <ul>
//                             <li>. Museografía y curaduría en espacios públicos y privados (hogares, restaurantes, hoteles, terrazas, etc.)</li>
//                             <li>. Gestión de proyectos culturales</li>
//                             <li>. Instalación temporal y permanente</li>
//                             <li>. Representación de artistas</li>
//                             <li>. Art Dealing</li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </Layout>
//     )
// }

const Line = styled.div`
    width: 4rem;
    height: 2px;
    background-color: var(--color-primary);
    margin-top: 1rem;
    margin-left: -2.5rem;
    margin-right: 1rem;
    @media only screen and (max-width: 26.5625em) {
        display: none;
    }
`

export default () => {

    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    },[ref.current])

    return (
        <Layout>
            <div className="services">
                <div style={{ alignItems: 'center', marginLeft: 0 }} className="about__pre">
                    <hr style={{ marginTop: '25rem' }} />
                </div>
                <div style={{ position: 'relative' }} className="services__container">
                    <div className="no-small" style={{ width: '2px', height: (height/2)+41, backgroundColor: 'var(--color-primary)', position: 'absolute', marginTop: '8.5rem', left: 0 }} />
                    <div ref={ref} className="content">
                        <div className="about__title" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                            <div style={{ width: '4rem', height: '2px', backgroundColor: 'var(--color-primary)', marginLeft: '-2.5rem', marginRight: '1rem' }} className="line-hor" />
                            <h1 css={css`
                                @media only screen and (max-width: 26.5625em) {
                                    text-align: center;
                                    width: 100%;
                                }
                            `}>Services</h1>
                        </div>
                        <ul css={css`
                            > li {
                                display: flex;
                                position: relative;
                                > span {
                                    flex: 1;
                                }
                            }
                        `}>
                            <li><Line /><span>Museografía y curaduría en espacios públicos y privados (hogares, restaurantes, hoteles, terrazas, etc.)</span></li>
                            <li><Line /><span>Gestión de proyectos culturales</span></li>
                            <li><Line /><span>Instalación temporal y permanente</span></li>
                            <li><Line /><span>Representación de artistas</span></li>
                            <li><Line /><span>Art Dealing</span></li>
                            <li><Line /><span>Dirección de Arte</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}